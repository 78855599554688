@import "variables";
@import "GTWalsheimPro";

body,
html {
  * {
    font-family: $header-font, sans-serif;
  }
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a {
  color: $red;
  &:hover {
    color: $red;
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.img {
  max-width: 100%;
}

.space-between {
  position: relative;
  display: flex;
  justify-content: space-between;
}

h1 {
  font-size: 60px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 1.2rem;
}

code {
  background: #e3e3e3;
  color: black;
  padding: 0 2px;
  margin-bottom: 4px;
  border: solid 1px #d6d6d6;
  border-radius: 4px;
  font-size: 14px;
  display: inline-block;
  font-family: "Source Code Pro", serif;
  & * {
    font-size: 14px;
    font-family: "Source Code Pro", serif;
  }
}

pre {
  border-radius: 10px;
  max-width: 100vw;
  code {
    font-size: 15px;
    background: none;
    border: none;
    max-width: 100%;
    & * {
      font-size: 15px;
    }
    & > span {
      flex-wrap: wrap;
    }
  }
}
.block-padding {
  padding: 75px 100px;
}

.flex-center-h {
  display: flex;
  justify-content: center;
}

.flex-center-v {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.site-block {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .row {
    margin: 0;
    padding: 0 80px;
  }
  width: 100%;
  position: relative;
  height: auto;
  min-height: 700px;
}

.first-block {
  padding-top: $navbar-height;
  min-height: calc(100vh - #{$navbar-height});
}

.green {
  background-color: #4cbfa0;
  color: white;
  transition: all 0.3s;
}

.bg-dark {
  background-color: $dark-blue;
  * {
    color: $white;
  }
}

.bg-white {
  background-color: white;
  * {
    color: black;
  }
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-dark-offset {
  background-color: $dark-blue-offset;
  color: $white;
}

.bg-light-blue {
  background: $light-blue;
  color: $white;
}

.highlight-color {
  color: $red;
}

//NAVBAR
.navbar {
  z-index: 1;
  a {
    color: white;
    text-decoration: none;
  }
  width: 100%;
  height: $navbar-height;
  background-color: $dark-blue;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  position: absolute;
  top: 15px;
  left: 25px;
  color: white;
  background-color: $red;
  padding: 10px;
  font-weight: 900;
}

.navbar-list {
  ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin: 0;
    li {
      list-style: none;
      margin: 0 20px;
    }
  }
}

.navbar-link {
  color: white !important;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1.25;
  margin: 0;
  transition: all 0.5s ease;
  font-family: $header-font;
  &:after {
    border: 2px white;
  }
  &:hover:after {
    //border-width: 3px;
  }
}

//MODALS
.ReactModal__Overlay--after-open {
  outline: none;
  z-index: 9000;
  background: white;
}
.blocker {
  z-index: 9000;
  padding: 0;
}

.portfolio-modal {
  outline: none;
  padding: 6%;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  z-index: 9000;
  border-radius: 0;
  background: white;
  //a.close-modal {
  //  background: none !important;
  //}
}

.modal-body {
  height: 45vh;
  resize: vertical;
  overflow: auto;
}

.modal-close {
  svg {
    width: 40px;
    transition: transform 0.24s ease;
    &:hover {
      transform: rotate(180deg);
    }
  }
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30pt;
  cursor: pointer;
}

// FOOTER
.footer {
  height: 180px;
  width: 100%;
  .col-sm-1 {
    margin: 0 15px;
  }
}

.footer-icon-block {
  cursor: pointer;
  background-color: $dark-blue-offset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  svg {
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -ms-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    height: 24px;
    width: 24px;
    path {
      fill: white;
    }
  }
  &:hover {
    background-color: $light-blue;
    svg {
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      -ms-transform: scale(1.15);
      -o-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
}

// PAGE BLOCKS
// Landing Page
.landing-page-block {
  height: 100vh;
  box-shadow: #f5f5f5 2px 5px 80px;
  .row {
    height: 100%;
    left: 0;
    padding: 0;
  }
}

.animation-wrapper {
  padding: 0px;
}

.landing-page-text {
  text-align: center;
}

//About block
.headshot-img {
  width: 350px;
  border-radius: 175px;
}

//Skills block
.download-pdf {
  width: 50px;
  cursor: pointer;
}

.icon-wrapper {
  background-color: $dark-blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-lg {
  svg {
    width: 150px;
  }
  width: 200px;
  height: 200px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.icon-sm {
  svg {
    width: 85px;
  }
  width: 150px;
  height: 150px;
  -webkit-border-radius: 75px;
  -moz-border-radius: 75px;
  border-radius: 75px;
}

.dev-skills-block {
  svg {
    path {
      fill: $white;
    }
    circle {
      fill: $white;
    }
  }
  p {
    text-align: center;
  }
  h2 {
    text-align: center;
  }
}

//Portfolio block {
.animate1 {
  -webkit-transition: all 900ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 900ms cubic-bezier(0.165, 0.84, 0.44, 1);
  * {
    -webkit-transition: all 900ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 900ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  //opacity: 0;
  //transform: translateY(100%);
}

.portfolio-grid-item {
  //background-color: $light-gray;
  //padding:5px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}

#portfolio-block-template {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

$animationTiming: 0.35s;

.portfolio-block {
  position: relative;
  background: none;
  float: left;
  width: 19vw;
  height: 19vw;
  margin: 1%;
  transition-timing-function: ease-in;
  transition: all $animationTiming ease-out;
  cursor: pointer;
  //padding:5px;
}

.portfolio-block > .inner-container {
  width: 99%;
  position: relative;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0 0px 10px #e4e4e4;
}

.portfolio-block span img {
  background-size: cover;
  display: inline;
}

.portfolio-block:hover .sidebar {
  visibility: visible;
  transform: translateX(50%);
}

.portfolio-block:hover .portfolio-picture {
  overflow: hidden;
  transform: translateX(-66%);
}

.portfolio-block .sidebar {
  position: relative;
  display: inline-block;
  transform: translateX(150%);
  background-color: $light-blue;
  width: 67%;
  height: 100%;
  -webkit-transition: transform $animationTiming ease-out,
    background-color 250ms;
  transition: transform $animationTiming ease-out, background-color 250ms;
}

.portfolio-block .sidebar:hover {
  background-color: $light-blue-hover;
}

.portfolio-block .sidebar span {
  overflow-wrap: break-word;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font: 14pt $header-font;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 100%;
}

.portfolio-block .portfolio-picture {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
  -webkit-transition: all $animationTiming ease-out;
  transition: all $animationTiming ease-out;
}

.portfolio-block .portfolio-picture img {
  margin: auto auto;
  width: 100%;
  height: 100%;
}

//PORTFOLIO MODAL

.lightslide-li {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.lSPager {
  margin: 0 auto !important;
}

.portfolio-grid-wrapper {
  padding: 20px;
  height: auto;
}

//Contact block

.footer-connecter {
  background-color: $dark-blue-offset;
  margin-bottom: -4px;
  path {
    stroke: white;
  }
  transform: scaleY(1.5);
}

.contact-block {
  height: 450px;
  min-height: 450px;
  flex-direction: column;
}

.contact-form-wrapper {
  width: 40%;
}

.contact-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 18px;
  .btn {
    margin-right: 0;
  }
}

input[type="email"],
input[type="text"],
textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: 0;
  padding: 10px 15px;
  background: $dark-blue;
  font-size: 12pt;
  margin-bottom: 3px;
  color: $white;
  &::placeholder {
    color: #bdbdbd;
  }
}

textarea {
  min-height: 120px;
}

//Buttons
.btn {
  margin: 10px;
  padding: 20px;
  background: none;
  font-size: 13pt;
  cursor: pointer;
  transition: background 0.2s ease;
  &:focus {
    outline: none;
  }
}

.btn-light {
  border: solid 2px $white;
  color: $white;
  &:hover {
    color: black;
    background-color: $white;
  }
}

.btn-dark {
  color: $dark-blue;
  border: solid 2px $dark-blue;
  &:hover {
    background-color: $dark-blue;
    color: $white;
  }
}

/// BLOG

.blog-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  padding: 0 7rem;
}

.blog-description {
  font-size: 18px;
}

.blog-container {
  max-width: 1000px;
  width: 100%;
  display: grid;
  padding-top: 135px;
  grid-template-rows: [h1] 5.5rem [h2] 2.5rem [paragraph] 11rem [tags] 2.5rem [blog-list] auto;
}

#blog-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  justify-items: center;
  margin-top: 1rem;
  padding-bottom: 5rem;
}

.blog-block {
  display: inline-table;
  padding: 1rem 1.75rem;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgb(214, 222, 205);
  position: relative;
  cursor: pointer;
  margin: 0;
  min-height: 170px;
  background: white;
  width: 100%;
  transition: all 0.08s ease;
  border: solid 1px #e1e1e1;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 10px rgb(197, 205, 188);
  }
}

.blog-title {
  font-size: 24px;
  min-height: 3rem;
}

.blog-block-tags {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  hr {
    margin: 0.4rem 0;
    width: 100%;
  }
  & > span {
    display: flex;
    gap: 6px;
    align-items: center;
    color: $red;
  }
}

.blog-block-plant-tags {
  position: absolute;
  top: 12px;
  right: 20px;
}

.blog-date-header {
  min-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
}

.tags-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.75rem;
}

.blog-tags-container {
  padding-left: 3px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.blog-tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  & > li {
    list-style: none;
    .blog-tag-link {
      border: none;
      color: $red;
      position: relative;
      z-index: 2;
      border-radius: 5px;
      transition: background 0.15s ease;
      background: white;
      cursor: text;
      outline: none;
      padding: 0;
      &.clickable {
        padding: 2px 6px;
        cursor: pointer;
        &:focus {
          outline: none;
        }

        &:hover {
          background: $red-light;
        }
      }

      &.active {
        background: $red;
        outline: none;
        color: white;
        &::before {
          animation: 0.1s forwards hover-v ease-in;
          animation-fill-mode: forwards;
        }
        &:hover {
          background: $red;
        }
      }
    }
  }
}

$seedlingColor: #41a545;
$saplingColor: #42821e;
$evergreenColor: #1a551f;
$plantHoverColor: rgba(30, 216, 85, 0.1);

.plant-tag-list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-left: 10px;
}

.plant-tag {
  border-radius: 5px;
  border: solid 1px;
  padding: 3px 10px;
  transition: all 0.3s ease;
  background-color: white;
  &.seedling {
    border-color: $seedlingColor;
    color: $seedlingColor;
    &:hover,
    &:focus {
      background-color: $plantHoverColor;
      outline: none;
    }
    &.active {
      outline: none;
      background-color: $seedlingColor;
      color: white;
    }
  }
  &.sapling {
    border-color: $saplingColor;
    color: $saplingColor;
    &:hover,
    &:focus {
      background-color: $plantHoverColor;
      outline: none;
    }
    &.active {
      outline: none;
      background-color: $saplingColor;
      color: white;
    }
  }
  &.evergreen {
    border-color: $evergreenColor;
    color: $evergreenColor;
    &:hover,
    &:focus {
      background-color: $plantHoverColor;
      outline: none;
    }
    &.active {
      outline: none;
      background-color: $evergreenColor;
      color: white;
    }
  }
}

// BLOG PAGE
.blog-page-container {
  max-width: 800px;
  margin: 6rem auto;
  margin-bottom: 8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.blog-header {
  width: 850px;
  margin-bottom: 4rem;
}

$standard-blog-spacing: 1.5rem;

#markdown-outlet {
  width: 75ch;
  display: flex;
  flex-direction: column;
  gap: $standard-blog-spacing;
  align-items: center;
  & > * {
    width: 100%;
  }
  h1 {
    font-size: 38px;
    &::after {
      display: block;
      transform: translateY(0.75rem);
      content: "";
      width: 100%;
      height: 1px;
      background: #e4e4e4;
    }
  }
  p,
  strong,
  em,
  li {
    margin: 0;
    font-size: 22px;
    // TODO: get a good readable serif font
    font-family: freight-text-pro, serif;
    letter-spacing: -0.003em;
    font-weight: 400;
    min-width: 100%;
  }
  p {
    font-style: normal;
  }
  li {
    margin: 0 auto;
    font-size: 20px;
    padding-left: 4px;
  }
  & ul li:not(:first-child),
  & ol li:not(:first-child) {
    margin-top: 1rem;
  }
  h5 {
    font-weight: bold;
  }
  img {
    margin: 0 auto;
    display: block;
    width: 50%;
  }
}
